import * as actionTypes from "../actions/actionsTypes";

const initialState = {
  raceSeriesList: {
    data: {
      "maxNoFleet": 0,
      "series": []
    },
  },
};

export const raceSeriesListReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_SERIES_OF_RACE_DATE:
      return {
        ...state,
        raceSeriesList: action.payload,
      };
    case actionTypes.UPDATE_SERIES_OF_RACE_DATE:
      return {
        ...state,
        raceSeriesList: action.payload,
      };
    default:
      return state;
  }
};
