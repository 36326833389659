import * as actionTypes from "../actions/actionsTypes";

const initialState = {
  results: {
    data: [],
  },
};

export const raceResultsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_RESULTS_BY_SESSION:
      return {
        ...state,
        results: action.payload,
      };
    case actionTypes.UPDATE_RESULTS:
      return {
        ...state,
        results: action.payload,
      };
    default:
      return state;
  }
};
