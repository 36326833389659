import * as actionTypes from '../actions/actionsTypes';

const initialState = {
    entrantImportSource: "TES",
  };
  
  const entrantImportSourceReducer = (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.ENTRANT_IMPORT_SOURCE:
        return {
          ...state,
          entrantImportSource: action.payload,
        };
      default:
        return state;
    }
  };
  
export default entrantImportSourceReducer;
