import * as actionTypes from '../actions/actionsTypes';

const initialState = {
      updatedRace: {
        data: false
    },
};

export const raceSessionDeleteUpdateReducer = (state = initialState, action) => {
  switch (action.type) {
      case actionTypes.DELETE_RACE_SESSION_DETAIL:
      return {
        ...state,
        updatedRace: action.payload,
      };
      case actionTypes.UPDATE_RACE_SESSION_DETAIL:
        return {
          ...state,
          updatedRace: action.payload,
        };
      case actionTypes.ABANDON_RACE:
          return {
            ...state,
            updatedRace: action.payload,
          };
    default:
      return state;
  }

};
