import React, { Suspense } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import routes from "./routes";

function RouterProvider() {
  const loaderStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh", // Full viewport height
  };
  return (
    <Routes>
      {routes.map((route, i) => {
        return (
          <Route
            key={i}
            path={route.path}
            element={
              <Suspense
                fallback={
                  <div style={loaderStyle}>
                    <CircularProgress />
                  </div>
                }
              >
                {route.component}
              </Suspense>
            }
          />
        );
      })}
    </Routes>
  );
}

export default RouterProvider;
