import * as actionTypes from '../actions/actionsTypes';

const initialState = {
  clubClassData: {
    data: []
  },
};

export const standardClubClassReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.STANDARD_CLUB_CLASSES:
      return {
        ...state,
        clubClassData: action.payload,
      };
    default:
      return state;
  }



};
