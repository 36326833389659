import * as actionTypes from "../actions/actionsTypes";

const initialKBState = {
  seriesKBData: {
    data: {
      seriesId: "",
      eventId: "",
      seriesName: "",
      yearCommence: new Date().getFullYear(),
      seriesInitials: "",
      normalRaceday: [],
      hide: 0,
      importTesEvent: [],
      importTesSeries: [],
      importAssoClub: [],
      importAssoClubEvent: [],
      importAssoClubSeries: [],
      type: 1,
      status: 0,
      boatSplit: 1,
      diffFleetDiffResultOpts: 1,
      sharing: [],
      cloneOption: 0,
      fleetClasses: [],
      resultOptions: [],
      resultOptionAllocations: [],
      scoringOptions: {
        seriesScoringOptionId: "",
        scoreId: "",
        penaltyId: "",
        dutyScoreAverge: "",
        discardAfter: [],
      },
    },
  },
};

const initialOTBState = {
  seriesOTBData: {
    data: {
      seriesId: "",
      eventId: "",
      seriesName: "",
      yearCommence: new Date().getFullYear(),
      seriesInitials: "",
      normalRaceday: [],
      hide: 0,
      importTesEvent: [],
      importTesSeries: [],
      importAssoClub: [],
      importAssoClubEvent: [],
      importAssoClubSeries: [],
      type: 2,
      status: 0,
      boatSplit: 1,
      diffFleetDiffResultOpts: 1,
      sharing: [],
      cloneOption: 0,
      fleetClasses: [],
      resultOptions: [],
      resultOptionAllocations: [],
      scoringOptions: {
        seriesScoringOptionId: "",
        scoreId: "",
        penaltyId: "",
        dutyScoreAverge: "",
        discardAfter: [],
      },
    },
  },
};

const initialState = {
  seriesData: {
    data: {
      seriesId: "",
      eventId: "",
      seriesName: "",
      yearCommence: new Date().getFullYear(),
      seriesInitials: "",
      normalRaceday: [],
      hide: 0,
      importTesEvent: [],
      importTesSeries: [],
      importAssoClub: [],
      importAssoClubEvent: [],
      importAssoClubSeries: [],
      type: 1,
      status: 0,
      boatSplit: 1,
      diffFleetDiffResultOpts: 1,
      sharing: [],
      cloneOption: 0,
      fleetClasses: [],
      resultOptions: [],
      resultOptionAllocations: [],
      scoringOptions: {
        seriesScoringOptionId: "",
        scoreId: "",
        penaltyId: "",
        dutyScoreAverge: "",
        discardAfter: [],
      },
    },
  },
};

export const seriesOTBReducer = (state = initialOTBState, action) => {
  switch (action.type) {
      case actionTypes.CREATE_UPDATE_SERIES_OTB_DATA:
        return {
          ...state,
          seriesOTBData: action.payload,
        };
      case actionTypes.EDIT_SERIES_OTB_DATA:
        return {
          ...state,
          seriesOTBData: action.payload,
        };
      case actionTypes.FETCH_SERIES_OTB_DATA:
      return {
        ...state,
        seriesOTBData: action.payload,
      };
      case actionTypes.CREATE_SERIES_OTB_FLEET_CLASSES:
      return {
          ...state,
          seriesOTBData: action.payload,
      };  
      case actionTypes.CREATE_SERIES_OTB_RESULT_OPTIONS:
        return {
            ...state,
            seriesOTBData: action.payload,
        };  
      case actionTypes.CREATE_SERIES_OTB_RESULT_OPTION_ALLOCATIONS:
        return {
            ...state,
            seriesOTBData: action.payload,
        };  
    case actionTypes.CREATE_SERIES_OTB_SCORE_OPTION:
    return {
        ...state,
        seriesOTBData: action.payload,
    };
    default:
      return state;
  }

};

export const seriesKBReducer = (state = initialKBState, action) => {
  switch (action.type) {
    case actionTypes.CREATE_UPDATE_SERIES_DATA:
      return {
        ...state,
        seriesKBData: action.payload,
      };
    case actionTypes.SELECTED_SERIES_DATA:
      return {
        ...state,
        seriesKBData: action.payload,
      };
    case actionTypes.EDIT_SERIES_DATA:
      return {
        ...state,
        seriesKBData: action.payload,
      };
    case actionTypes.FETCH_SERIES_KB_DATA:
      return {
        ...state,
        seriesKBData: action.payload,
      };
    
    case actionTypes.CREATE_SERIES_FLEET_CLASSES:
      return {
        ...state,
        seriesKBData: action.payload,
      };
    case actionTypes.CREATE_SERIES_RESULT_OPTIONS:
      return {
        ...state,
        seriesKBData: action.payload,
      };
    case actionTypes.CREATE_SERIES_RESULT_OPTION_ALLOCATIONS:
      return {
        ...state,
        seriesKBData: action.payload,
      };
    case actionTypes.CREATE_SERIES_SCORE_OPTION:
      return {
        ...state,
        seriesKBData: action.payload,
      };
    default:
      return state;
  }
};

export const seriesReducer = (state = initialState, action) => {
  switch (action.type) {
   
    case actionTypes.FETCH_SERIES_DATA:
      return {
        ...state,
        seriesData: action.payload,
      }
    default:
      return state;
  }
};
