import * as actionTypes from '../actions/actionsTypes';

const initialState = {
  incompleteSeriesData: {
        data: null
    },
};

export const incompleteSeriesReducer = (state = initialState, action) => {
  switch (action.type) {
      case actionTypes.FETCH_INCOMPLETE_SERIES_DATA:
      return {
        ...state,
        incompleteSeriesData: action.payload,
      };
      case actionTypes.DELETE_INCOMPLETE_SERIES_DATA:
      return {
        ...state,
        incompleteSeriesData: action.payload
      };
    default:
      return state;
  }

};
