import * as actionTypes from '../actions/actionsTypes';

const initialState = {
    seriesEntrants: {
        data: [],
    },
};

export const seriesEntrantsReducer = (state = initialState, action) => {
  switch (action.type) {
      case actionTypes.FETCH_SERIES_RACE_ENTRANTS:
          return {
            ...state,
            seriesEntrants: action.payload,
          };
    default:
      return state;
  }

};
