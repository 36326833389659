import * as actionTypes from "../actions/actionsTypes";

const initialState = {
  seriesList: {
    data: [],
  },
};

export const seriesListReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_SERIES_LIST:
      return {
        ...state,
        seriesList: action.payload,
      };
    case actionTypes.FETCH_SERIES_BY_RACE_SESSION:
      return {
        ...state,
        seriesList: action.payload,
      };
    case "EMPTY":
      return {
        ...state,
        seriesList: action.payload,
      };
    default:
      return state;
  }
};
