import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Input from "@mui/material/Input";
import FormControl from "@mui/material/FormControl";
import Divider from "@mui/material/Divider";
import InnerPageheading from "../../components/InnerPageheading";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import axios from "axios";
import loginImage from "../../assets/images/LoginImage.png";
import { useNavigate } from "react-router-dom";

const primaryColor = getComputedStyle(document.documentElement)
  .getPropertyValue("--ty-primary-color")
  .trim();

const theme = createTheme({
  typography: {
    fontFamily: "var(--ty-font-family)",
  },
  palette: {
    primary: {
      main: primaryColor,
    },
  },
});

const ForgotPassword = () => {
  const [email, setEmail] = React.useState("");
  const [status, setStatus] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [msg, setMsg] = React.useState("");
  const navigate = useNavigate();
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleClose = () => {
    setStatus(false); // Set status to false to remove the Stack containing the Alert
  };

  const handleContinue = () => {
    const domain = window.location.origin;
    console.log(domain);
    if (!validateEmail(email)) {
      setStatus(true);
      setMsg("Please enter a valid email address.");
      return;
    }
    setLoading(true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "api/auth/forgot-password",
        { domain: domain, email: email },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        if (response.data.success) {
          setStatus(true);
          setLoading(false);
          setMsg(
            "We have sent a password reset link to your registered email address. Please click the link to complete the reset process."
          );
        } else {
          setStatus(true);
          setLoading(false);
          console.log(response);
          setMsg(response.data.message);
        }
      })
      .catch((error) => {
        setStatus(true);
        setLoading(false);
        setMsg(error.response.data.message);
        console.error(error);
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <div>
        <Box
          className="ty_application_design"
          sx={{
            backgroundImage: "none",
          }}
        >
          <Box className="authentication_screens">
            <Box
              sx={{
                height: "100%",
              }}
            >
              <video
                autoPlay
                loop
                height="100%"
                muted
                // poster="https://topyacht.s3.us-east-2.amazonaws.com/TopYacht-Login-Video.mp4"
                poster={loginImage}
              >
                <source
                  src="https://topyacht.s3.us-east-2.amazonaws.com/TopYacht-Login-Video.mp4"
                  type="video/mp4"
                  autoplay="autoplay"
                  muted=""
                  width="100%"
                  height="100vh"
                  webkit-playsinline=""
                  playsinline=""
                  loop=""
                />
              </video>
            </Box>
            <Box className="main_wrapper">
              <Grid container justifyContent="center" alignItems="center">
                <Grid item lg={8} md={7} sm={12} xs={12}>
                  <Box className="loginPageLeft">
                    <img
                      className="animationLoginPage"
                      src="/assets/images/top_YatchLogo.svg"
                      title="Top Yacht"
                      alt="Top Yacht"
                    />

                    <Box>
                      <Divider className="dividerAnimation" />
                    </Box>
                    <Box className="animationLoginPage">
                      <Typography variant="p">
                        <p>
                          The world’s most comprehensive software suite for
                          sailing results management
                        </p>
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item lg={4} md={5} sm={12} xs={12}>
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid
                      container
                      spacing={0}
                      sx={{
                        justifyContent: "space-between",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <InnerPageheading headerName="Login" />
                    </Grid>
                  </Box>
                  <Box className="loginBox">
                    {status && (
                      <Stack sx={{ width: "100%" }} spacing={2}>
                        <Alert
                          icon={false}
                          className="alertBox"
                          severity="error"
                          onClose={handleClose}
                        >
                          {msg}
                        </Alert>
                      </Stack>
                    )}

                    <Button className="backBtn">
                      <div
                        onClick={() => {
                          navigate("/login");
                        }}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: "5px 10px",
                          borderRadius: "8px",
                        }}
                      >
                        <ArrowBackIosIcon className="backIcon" />
                        Back
                      </div>
                    </Button>
                    <Box
                      className="forgotPwText"
                      sx={{
                        margin: "40px 0 25px",
                      }}
                    >
                      <Typography variant="h6" sx={{ marginBottom: "10px" }}>
                        Forgot your password?
                      </Typography>
                      <Typography variant="p">
                        Type your email below to reset your password.
                      </Typography>
                    </Box>

                    <Box className="loginFields">
                      <FormLabel
                        className="ty-uppercase-label"
                        sx={{
                          display: "block",
                          width: "100%",
                          marginBottom: "10px",
                        }}
                      >
                        Email Address{" "}
                      </FormLabel>
                      <FormControl fullWidth>
                        <Input
                          value={email}
                          size="small"
                          placeholder="Enter email address"
                          sx={{
                            padding: "8.5px 14px",
                            "&::after": {
                              display: "none",
                            },
                            "&::before": {
                              display: "none",
                            },
                          }}
                          id="standard-username"
                          onChange={handleEmailChange}
                        />
                      </FormControl>
                    </Box>
                    <Box className="loginBoxBottom forgotScreen">
                      <Button
                        className="ty_primary_btn"
                        onClick={handleContinue}
                        disabled={loading ? true : false}
                      >
                        {loading ? "Loading..." : "Continue"}
                        <ArrowForwardIosIcon className="loginIcon" />
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </div>
    </ThemeProvider>
  );
};

export default ForgotPassword;
