import * as actionTypes from '../actions/actionsTypes';

const initialKBState = {
  seriesKBNameList: {
    data: []
  }
};

const initialOTBState = {
  seriesOTBNameList: {
    data: []
  }
};

const initialNameListState = {
  seriesNameList: {
    data: []
  }
};

export const seriesKBNameListReducer = (state = initialKBState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_SERIES_KB_NAME_LIST:
      return {
        ...state,
        seriesKBNameList: action.payload,
      };
    case "EMPTY_KB_NAME_LIST":
      return {
        ...state,
        seriesKBNameList: action.payload,
      };
    default:
      return state;
  }
};

export const seriesOTBNameListReducer = (state = initialOTBState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_SERIES_OTB_NAME_LIST:
      return {
        ...state,
        seriesOTBNameList: action.payload,
      };
      case "EMPTY_OTB_NAME_LIST":
        return {
          ...state,
          seriesOTBNameList: action.payload,
        };
    default:
      return state;
  }
};

export const seriesNameListReducer = (state = initialNameListState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_SERIES_NAME_LIST:
      return {
        ...state,
        seriesNameList: action.payload,
      };
      case "EMPTY_NAME_LIST":
        return {
          ...state,
          seriesNameList: action.payload,
        };
    default:
      return state;
  }
};
