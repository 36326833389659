import * as actionTypes from '../actions/actionsTypes';

const initialKBState = {
  eventKBList: {
    data: []
  }
};

const initialOTBState = {
  eventOTBList: {
    data: []
  }
};

const initialState = {
  eventList: {
    data: []
  },
};

export const eventKBListReducer = (state = initialKBState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_EVENT_KB_LIST:
      return {
        ...state,
        eventKBList: action.payload,
      };
    default:
      return state;
  }
};

export const eventOTBListReducer = (state = initialOTBState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_EVENT_OTB_LIST:
      return {
        ...state,
        eventOTBList: action.payload,
      };
    default:
      return state;
  }
};

export const eventListReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_EVENT_LIST:
      return {
        ...state,
        eventList: action.payload,
      };
    default:
      return state;
  }
};
