import * as actionTypes from '../actions/actionsTypes';

const initialState = {
    singleRaceSessionData: {
        data: {},
    },
};

export const singleRaceSessionReducer = (state = initialState, action) => {
  switch (action.type) {
      case actionTypes.FETCH_RACE_SESSION_BY_ID:
        return {
          ...state,
          singleRaceSessionData: action.payload,
        };
     
    default:
      return state;
  }

};
