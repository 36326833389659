export const menuData = [
  {
    text: "Race Day",
    iconLink: "/assets/icons/race.svg",
    subItems: [
      {
        name: "Race Session Setup",
        link: "/raceday/race-session",
        id: "RACE_SESSION_SETUP",
      },
      { name: "KB Entrants", link: "/entrants/kb-entrants", id: "ENTRANTS" },
      { name: "OTB Entrants", link: "/entrants/otb-entrants", id: "ENTRANTS" },
      {
        name: "Finish time entry",
        link: "/raceday/finish-time-entry",
        id: "FINISH_TIME_ENTRY",
      },
      {
        name: "Races Status",
        link: "/raceday/race-status",
        id: "RACE_OVERVIEW",
      },
      {
        name: "Sharing Race Session data",
        link: "/raceday/sharing-race-session-data",
        id: "SHARING_RACE_SESSION_DATA",
      },
    ],
  },
  {
    text: "Reports",
    iconLink: "/assets/icons/reports.svg",
    subItems: [
      { name: "Results", link: "/reports/result", id: "RESULTS" },
      { name: "Today's winners", link: "", id: "TODAYS_WINNER" },
      { name: "Entrants", link: "", id: "ENTRANTS" },
      { name: "Handicap", link: "/reports/handicap", id: "HANDICAP" },
    ],
  },
  {
    text: "SetUp",
    iconLink: "/assets/icons/setup.svg",
    subItems: [
      {
        name: "Event Setup",
        link: "/setup/event-setup",
        id: "EVENT_SETUP",
      },
      {
        name: "Series Setup - KB",
        link: "/setup/series-setup-kb",
        id: "SERIES_SETUP_KB",
      },
      {
        name: "Series Setup - OTB",
        link: "/setup/series-setup-otb",
        id: "SERIES_SETUP_OTB",
      },
      { name: "Race Calendar", link: "/setup/race-days", id: "RACE_CALENDAR" },
    ],
  },
  {
    text: "Configuration",
    iconLink: "/assets/icons/configuration.svg",
    subItems: [
      { name: "Club", link: "/configuration/club", id: "CLUB_SETUP" },
      { name: "HC Defs", link: "/configuration/HcDefs", id: "HC_DEF" },
      {
        name: "Score Point Defs",
        link: "/configuration/score-point-defs",
        id: "SCORE_DEFINITION",
      },
      {
        name: "Score Penalty Defs",
        link: "/configuration/score-penality-defs",
        id: "SCORE_PENALTY",
      },
    ],
  },
];
