import * as actionTypes from '../actions/actionsTypes';

const initialState = {
    triggerCSVImport: false,
  };
  
  const triggerCSVImportReducer = (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.TRIGGER_CSV_IMPORT:
        return {
          ...state,
          triggerCSVImport: !state.triggerCSVImport,
        };
      default:
        return state;
    }
  };
  
export default triggerCSVImportReducer;
