import * as actionTypes from "../actions/actionsTypes";

const initialState = {
  signedOn: {
    data: [],
  },
};

export const signedOnReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_SIGNED_ON:
      return {
        ...state,
        signedOn: action.payload,
      };
    case actionTypes.FETCH_PREVIOUS_RACE_SERIES:
      return {
        ...state,
        signedOn: action.payload,
      };
    default:
      return state;
  }
};
