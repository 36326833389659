import * as actionTypes from '../actions/actionsTypes';

const initialState = {
    raceSessionNameList: {
        data: [],
    },
};

export const raceSessionNameListReducer = (state = initialState, action) => {
  switch (action.type) {
      case actionTypes.FETCH_RACE_SESSION_NAMES_BY_SERIES:
        return {
          ...state,
          raceSessionNameList: action.payload,
        };
    default:
      return state;
  }

};
