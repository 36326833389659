import * as actionTypes from '../actions/actionsTypes';

const initialState = {
    triggerTESImport: false,
  };
  
  const triggerTESImportReducer = (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.TRIGGER_TES_IMPORT:
        return {
          ...state,
          triggerTESImport: !state.triggerTESImport,
        };
      default:
        return state;
    }
  };
  
export default triggerTESImportReducer;
