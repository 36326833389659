import * as actionTypes from "../actions/actionsTypes";

const initialState = {
  scores: {
    data: []
  },
};

export const seriesScoresReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_SERIES_SCORES:
        return {
          ...state,
          scores: action.payload,
        };
    default:
      return state;
  }
};
