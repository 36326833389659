import * as actionTypes from '../actions/actionsTypes';

const initialState = {
  entrantsSeriesList: {
    data: []
  },
};

export const entrantsSeriesListReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ENTRANTS_SERIES_LIST:
      return {
        ...state,
        entrantsSeriesList: action.payload,
      };
    default:
      return state;
  }
};
