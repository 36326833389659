import * as actionTypes from '../actions/actionsTypes';

const initialState = {
    raceEntrants: {
        data: [],
    },
};

export const raceEntrantsReducer = (state = initialState, action) => {
  switch (action.type) {
      case actionTypes.CREATE_UPDATE_RACE_ENTRANTS:
        return {
          ...state,
          raceEntrants: action.payload,
        };
      case actionTypes.FETCH_RACE_ENTRANTS:
          return {
            ...state,
            raceEntrants: action.payload,
          };
    default:
      return state;
  }

};
