import * as actionTypes from '../actions/actionsTypes';

const initialState = {
    entrantsSeriesData: {
        data: {
            "seriesId": "",
            "eventId": "",
            "seriesName": "",
            "yearCommence": new Date().getFullYear(),
            "seriesInitials": "",
            "normalRaceday": [],
            "hide": 0,
            "importTesEvent": [],
            "importTesSeries": [],
            "importAssoClub": [],
            "importAssoClubEvent": [],
            "importAssoClubSeries": [],
            "type":1,
            "status": 0,
            "boatSplit":1,
            "sharing":[],
            "cloneOption":0,
            "fleetClasses":[],
            "resultOptions": [],
            "resultOptionAllocations": [],
            "scoringOptions":{
                "seriesScoringOptionId": "",
                "scoreId": '',
                "penaltyId": '',
                "dutyScoreAverge":'',
                "discardAfter":[]
            }
        }
    },
};

export const entrantsSeriesReducer = (state = initialState, action) => {
  switch (action.type) {
      case actionTypes.FETCH_ENTRANTS_SERIES_DATA:
      return {
        ...state,
        entrantsSeriesData: action.payload,
      }
    default:
      return state;
  }

};
