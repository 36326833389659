import axios from 'axios';
import {fetchrefreshToken} from './../actions/actions';

//Frontend layer to expire the user session after 30 mins
const THIRTY_MINUTES = 30 * 60 * 1000;

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL, // Set the base URL for all requests
});

// Add request interceptor for token handling
axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    const lastRequestTime = localStorage.getItem('lastRequestTime');
    const currentTime = new Date().getTime();

    if (lastRequestTime && currentTime - lastRequestTime > THIRTY_MINUTES) {
        // If the time difference is greater than 30 minutes, clear local storage
        localStorage.clear();
        window.location.reload();  // Refresh the page
        return Promise.reject("Session expired, reloading page.");
    }

    // Update last request time in localStorage
    localStorage.setItem('lastRequestTime', currentTime);
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add response interceptor for handling 401 errors
axiosInstance.interceptors.response.use(
  async (response) => {
    console.log(response);
    return response;
  },
  async (error) => {let originalRequest = error.config;
      if (error.response && error.response.status === 401) {
          // localStorage.clear();
          // alert('session expired');
          const requestData={
              "token" :localStorage.getItem('refreshtoken'), "userID":localStorage.getItem('user_id')
          }
          const res = await fetchrefreshToken(requestData);
          if(res.status){
              const newtoken = res.data

              localStorage.setItem("accessToken",newtoken);
              originalRequest = {
                  ...originalRequest,
                  headers: {
                      ...originalRequest.headers,
                      Authorization: `Bearer ${newtoken}`,
                  },
              };
              return axiosInstance(originalRequest);
          }else{
              localStorage.clear();
              window.location.reload();
          }


      }else{
          return Promise.reject(error);
      }
  }
);

export default axiosInstance;
