import * as actionTypes from "../actions/actionsTypes";

const initialState = {
  eventData: {
    data: {
      eventId: "",
      eventName: "",
      eventYear: "",
      visitorsCasuals: 0,
      type: 1,
      multipleRaceAreas: "",
      cloneOption: 0,
      entrantsSource: [],
    },
  },
};

const initialKBState = {
  eventKBData: {
    data: {
      eventId: "",
      eventName: "",
      eventYear: "",
      visitorsCasuals: 0,
      type: 1,
      multipleRaceAreas: "",
      cloneOption: 0,
      entrantsSource: [],
    },
  },
};

const initialOTBState = {
  eventOTBData: {
    data: {
      eventId: "",
      eventName: "",
      eventYear: "",
      visitorsCasuals: 0,
      type: 1,
      multipleRaceAreas: "",
      cloneOption: 0,
      entrantsSource: [],
    },
  },
};

export const eventReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CREATE_EVENT_DATA:
      return {
        ...state,
        eventData: action.payload,
      };
    case actionTypes.UPDATE_EVENT_DATA:
      return {
        ...state,
        eventData: action.payload,
      };
    case actionTypes.SELECTED_EVENT_DATA:
      return {
        ...state,
        eventData: action.payload,
      };
    default:
      return state;
  }
};

export const eventKBReducer = (state = initialKBState, action) => {
  switch (action.type) {
    case actionTypes.SELECTED_EVENT_KB_DATA:
      return {
        ...state,
        eventKBData: action.payload,
      };
    default:
      return state;
  }
};
export const eventOTBReducer = (state = initialOTBState, action) => {
  switch (action.type) {
    case actionTypes.SELECTED_EVENT_OTB_DATA:
      return {
        ...state,
        eventOTBData: action.payload,
      };
    default:
      return state;
  }
};