import * as actionTypes from '../actions/actionsTypes';

const initialState = {
		offsetResultsList: {
	        data: [],
	    },
		offsetEntrantsList: {
        data: [],
    },
};

export const entrantOffsetReducer = (state = initialState, action) => {
  switch (action.type) {
      case actionTypes.FETCH_ENTRANT_OFFSETS:
        return {
          ...state,
          offsetResultsList: action.payload,
        };
      case actionTypes.FETCH_OFFSETS:
          return {
            ...state,
            offsetEntrantsList: action.payload,
          };
    default:
      return state;
  }

};
