import * as actionTypes from "../actions/actionsTypes";

const initialState = {
  publishedResults: {
	    data: []
	  }
};

export const publishedResultsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_PUBLISHED_RESULTS_BY_SESSION:
      return {
        ...state,
        publishedResults: action.payload,
      };
    case actionTypes.FETCH_PUBLISHED_RESULTS_BY_SESSION:
        return {
          ...state,
          publishedResults: action.payload,
        };
    default:
      return state;
  }
};
