import * as actionTypes from "../actions/actionsTypes";

const initialState = {
  TESEntrants: {
    data: [],
  },
};

export const TESEntrantsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.STORE_TES_ENTRANTS:
      return {
        ...state,
      };
      case actionTypes.FETCH_TES_ENTRANTS:
        return {
          ...state,
          TESEntrants: action.payload,
        };
    default:
      return state;
  }
};
