import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import FormControl from "@mui/material/FormControl";
import Divider from "@mui/material/Divider";
import InnerPageheading from "../../components/InnerPageheading";
import Paper from "@mui/material/Paper";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import CustomizedSnackbars from "../../components/Toast";
import { z } from "zod";

const primaryColor = getComputedStyle(document.documentElement)
  .getPropertyValue("--ty-primary-color")
  .trim();

const theme = createTheme({
  typography: {
    fontFamily: "var(--ty-font-family)",
  },
  palette: {
    primary: {
      main: primaryColor,
    },
  },
});

const schema = z.object({
  newPassword: z.string().min(1, "New Password is required"),
  confirmPassword: z.string().min(1, "Confirm Password is required"),
}).refine((data) => data.newPassword === data.confirmPassword, {
  path: ["confirmPassword"],
  message: "Passwords do not match",
})

const ResetPassword = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const token = new URLSearchParams(search).get("token");

  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const [newPassword, setNewPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [error, setError] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [snackbar, setSnackbar] = React.useState({ text: "", severity: "" });
  const [showToast, setShowToast] = React.useState(false);
  const [errors, setErrors] = React.useState({});

  const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleNewPasswordChange = (e) => {
    setError(false);
    setNewPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setError(false);
    setConfirmPassword(e.target.value);
  };

  function validationPopup(msg, status) {
    setShowToast(true);
    setSnackbar({ text: msg, severity: status });
    setTimeout(() => {
      setSnackbar({ text: "", severity: status });
      setShowToast(false);
    }, 2000);
  }

  const validateForm = (data) => {
    try {
      schema.parse(data);
      setErrors({});
      return true;
    } catch (error) {
      if (error.errors && error.errors.length > 0) {
        console.log(error.errors);
        const validationErrors = {};
        validationPopup(error.errors[0].message, "error");
        error.errors.forEach((err) => {
          const fieldName = err.path.join(".");
          validationErrors[fieldName] = err.message;
        });
        setErrors(validationErrors);
      }
      return false;
    }
  };

  const handleUpdatePasswordSubmit = () => {
    const data = { newPassword, confirmPassword };

    if (!validateForm(data)) {
      console.log(errors);
      return;
    }
    
    axios
      .put(
        process.env.REACT_APP_API_BASE_URL + "api/auth/reset-password",
        { token: token, password: newPassword },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      
      .then((response) => {
        if (response.data.success) {
          setOpen(true);
          validationPopup("Details saved successfully!", "success");
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          setOpen(true);
          validationPopup(response.data.message, "error");
        }
      })
      .catch((error) => {
        setOpen(true);
        validationPopup("There is some problem while resetting the password", "error");
        console.error(error);
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <div>
        <CustomizedSnackbars
          severity={snackbar.severity}
          open={snackbar.text !== ""}
          setShowToast={setShowToast}
          text={snackbar.text}
        />
        <Box
          className="ty_application_design"
          sx={{
            backgroundImage: "none",
          }}
        >
          <Box className="authentication_screens">
            <Box
              sx={{
                height: "100%",
              }}
            >
              <video
                autoPlay
                loop
                height="100%"
                muted
                poster="https://topyacht.s3.us-east-2.amazonaws.com/TopYacht-Login-Video.mp4"
              >
                <source
                  src="https://topyacht.s3.us-east-2.amazonaws.com/TopYacht-Login-Video.mp4"
                  type="video/mp4"
                  autoplay="autoplay"
                  muted=""
                  width="100%"
                  height="100vh"
                  webkit-playsinline=""
                  playsinline=""
                  loop=""
                />
              </video>
            </Box>
            <Box className="main_wrapper">
              <Grid container justifyContent="center" alignItems="center">
                <Grid item lg={8} md={7} sm={12} xs={12}>
                  <Box className="loginPageLeft">
                    <img
                      className="animationLoginPage"
                      src="/assets/images/top_YatchLogo.svg"
                      title="Top Yacht"
                      alt="Top Yacht"
                    />
                    <Box>
                      <Divider className="dividerAnimation" />
                    </Box>
                    <Box className="animationLoginPage">
                      <Typography variant="p">
                        <p>
                          The world’s most comprehensive software suite for
                          sailing results management
                        </p>
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item lg={4} md={5} sm={12} xs={12}>
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid
                      container
                      spacing={0}
                      sx={{
                        justifyContent: "space-between",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <InnerPageheading headerName="Login" />
                    </Grid>
                  </Box>
                  <Box className="loginBox">
                    <Box
                      sx={{
                        marginBottom: "40px",
                      }}
                    >
                      <Typography variant="h6">Reset Password</Typography>
                    </Box>

                    <Box className="loginFields">
                      <Box>
                        <FormLabel
                          className={
                            error
                              ? "ty-uppercase-label errorLabelColor"
                              : "ty-uppercase-label"
                          }
                          sx={{
                            display: "block",
                            width: "100%",
                          }}
                        >
                          Enter New Password{" "}
                        </FormLabel>
                      </Box>
                      <FormControl fullWidth>
                        <Input
                          value={newPassword}
                          onChange={handleNewPasswordChange}
                          size="small"
                          placeholder="Enter password"
                          sx={{
                            padding: "8.5px 14px",
                            "&::after": {
                              display: "none",
                            },
                            "&::before": {
                              display: "none",
                            },
                          }}
                          id="standard-password"
                          type={showNewPassword ? "text" : "password"}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowNewPassword}
                                onMouseDown={handleMouseDownPassword}
                                sx={{
                                  color: "#356DAD",
                                }}
                              >
                                {showNewPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </FormControl>
                    
                    </Box>
                    <Box className="loginFields">
                      <Box>
                        <FormLabel
                          className={
                            error
                              ? "ty-uppercase-label errorLabelColor"
                              : "ty-uppercase-label"
                          }
                          sx={{
                            display: "block",
                            width: "100%",
                          }}
                        >
                          Confirm New Password{" "}
                        </FormLabel>
                      </Box>
                      <FormControl fullWidth>
                        <Input
                          value={confirmPassword}
                          onChange={handleConfirmPasswordChange}
                          size="small"
                          placeholder="Match password"
                          sx={{
                            padding: "8.5px 14px",
                            "&::after": {
                              display: "none",
                            },
                            "&::before": {
                              display: "none",
                            },
                          }}
                          id="standard-password"
                          type={showConfirmPassword ? "text" : "password"}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowConfirmPassword}
                                onMouseDown={handleMouseDownPassword}
                                sx={{
                                  color: "#356DAD",
                                }}
                              >
                                {showConfirmPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </FormControl>
                    </Box>
                    <Box className="loginBoxBottom forgotScreen">
                      <Button
                        className="ty_primary_btn updatePwBtn"
                        onClick={handleUpdatePasswordSubmit}
                      >
                        Update Password
                        <ArrowForwardIosIcon className="loginIcon" />
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </div>
    </ThemeProvider>
  );
};

export default ResetPassword;
