import * as actionTypes from '../actions/actionsTypes';

const initialState = {
    raceSessionData: {
        data: [],
    },
};

export const raceSessionReducer = (state = initialState, action) => {
  switch (action.type) {
      case actionTypes.FETCH_RACE_SESSION:
        return {
          ...state,
          raceSessionData: action.payload,
        };
      case actionTypes.UPDATED_RACE_SESSION:
        return {
          ...state,
          raceSessionData: action.payload,
        };
      case actionTypes.CREATE_UPDATE_RACE_SESSION:
        return {
          ...state,
          raceSessionData: action.payload,
        };
        case actionTypes.MOVE_RACE_SESSION:
          return {
            ...state,
            raceSessionData: action.payload,
          };
      case actionTypes.FETCH_RACE_SESSION_BY_SERIES:
          return {
            ...state,
            raceSessionData: action.payload,
          };
      case actionTypes.FETCH_RACE_SESSION_BY_SERIES_ONLY:
          return {
            ...state,
            raceSessionData: action.payload,
          };
      case actionTypes.CLONE_LAST_RACE_DAY:
        return {
          ...state,
          raceSessionData: action.payload,
        };
      case actionTypes.CLONE_LAST_RACE_DAY_NORMAL_RACE:
        return {
          ...state,
          raceSessionData: action.payload,
        };
        case "EMPTY":
        return {
          ...state,
          raceSessionData: action.payload,
        };
    default:
      return state;
      
  }

};
