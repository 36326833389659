import * as actionTypes from "../actions/actionsTypes";

const initialState = {
  raceSessionOverviewData: {
    data: [],
  },
};

export const raceSessionOverviewReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_RACE_SESSION_BY_EVENT:
      return {
        ...state,
        raceSessionOverviewData: action.payload,
      };
    case actionTypes.CHANGE_RACE_DATE:
      return {
        ...state,
      };
    default:
      return state;
  }
};
